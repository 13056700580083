<template>
  <div class="tags" :class="{on : pageLoaded}">
      <div class="tags-title">
        <c-image iUrl="icon-tag_2" iClass="icon" iWidth="36" iHeight="36"></c-image>
        <h1>画板管理</h1>
      </div>
      <div class="tags-list">
          <div class="tags-list-content">
            <div class="btn_add" @click="onPageToAddTag('add')">
                <c-image iUrl="icon-add_2" iClass="icon" iWidth="16" iHeight="16"></c-image>
                添加画板
            </div>
            <div class="item" :class="{on:selectTag == item.id ? true : false}" v-for="(item,index) in tags" :key="index" @click="onSelectTag(item.id)">{{item.label_name}}</div>
          </div>
      </div>

      <div class="tags-btns" :class="{on:isSelect}">
          <div class="btn del" @click="onShowDialog">
              <c-image v-if="isSelect" iUrl="icon-delete_2" iClass="icon" iWidth="16" iHeight="16"></c-image>
              <c-image v-else iUrl="icon-delete_1" iClass="icon" iWidth="16" iHeight="16"></c-image>
              删除
          </div>

          <div class="btn del" @click="onPageToAddTag('edit')">
              <c-image v-if="isSelect" iUrl="icon-edit_2" iClass="icon" iWidth="16" iHeight="16"></c-image>
              <c-image v-else iUrl="icon-edit_1" iClass="icon" iWidth="16" iHeight="16"></c-image>
              修改
          </div>
      </div>


      <van-overlay :show="showDialog" @click="showDialog = false">
          <!-- dialog cue -->
          <div class="common-dialog" @click.stop v-if="dialogType == 'cue'">
              <div class="icon_close" @click="showDialog = false">
                <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
            </div>

            <c-image iUrl="icon-cue" iClass="icon" iWidth="36" iHeight="36"></c-image>
            <h1>温馨提示</h1>
            <p>您需要先选中画板<br/>才可以进行“删除”与“修改”</p>
          </div>

          <!-- dialog delete -->
          <div class="common-dialog" @click.stop v-if="dialogType == 'delete_tag'">
                <div class="icon_close" @click="showDialog = false">
                    <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
                </div>

                <c-image iUrl="icon-warn" iClass="icon" iWidth="36" iHeight="36"></c-image>
                <h1>确定删除画板？</h1>
                <p>不影响作品的收藏<br/>没有画板的作品将被收藏至“待归类”下</p>
                <div class="btn" @click="onDeleteTag">确定</div>
          </div>
      </van-overlay>
  </div>
</template>

<script>
import { Overlay } from "vant";
import CImage from "../../components/CImage";
import { mapActions, mapState, mapMutations } from "vuex";
export default {
    components: {
        [CImage.name]: CImage,
        [Overlay.name]: Overlay,
    },
    data() {
        return {
            pageLoaded: false,
            showDialog: false,
            dialogType: "",
            selectTag: 0,
            isSelect: false,
        };
    },
    computed: {
        ...mapState({
            tags: (state) => state.collect.tags,
            os: (state) => state.os,
        }),
    },
    watch: {},
    methods: {
        ...mapActions({
            requestUserLabels: "collect/requestUserLabels",
            requestLabelDel: "collect/requestLabelDel",
        }),
        ...mapMutations({
            setTags: "collect/SET_TAGS",
        }),
        onSelectTag(id) {
            this.selectTag = id;
            this.isSelect = true;
        },
        onShowDialog() {
            this.showDialog = true;
            if (this.isSelect) this.dialogType = "delete_tag";
            else this.dialogType = "cue";
        },
        onDeleteTag() {
            this.isSelect = false;
            this.showDialog = false;
            let _list = this.tags.filter((v, i) => {
                return v.id != this.selectTag;
            });

            this.setTags(_list);
            this.requestLabelDel({ label_id: this.selectTag })
        },
        onPageToAddTag(type) {
            if (type == "add") {
                this.$router.push({ name: "add_tag" });
            } else if (type == "edit") {
                if (this.selectTag) {
                    let _tagObj = this.tags.filter((v, i) => {
                        return v.id == this.selectTag;
                    });

                    this.$router.push({
                        name: "add_tag",
                        params: {
                            id: _tagObj[0].id,
                            tag: _tagObj[0].label_name,
                        },
                    });
                } else {
                    this.showDialog = true;
                    this.dialogType = "cue";
                }
            }
        },
    },
    created() {
        document.body.classList.add('bg-white')
        
        this.requestUserLabels().then(() => {
            this.pageLoaded = true;
        });
    },
    destroyed() {
        document.body.classList.remove('bg-white')
    }
};
</script>

<style>
.tags{padding-top: 47px; height: 100vh; background: #fff; opacity: 0;}
.tags.on{opacity: 1;}
.tags-list{padding: 20px 20px 0 ; display: flex; justify-content: flex-start; flex-wrap: wrap;}
.tags-title .icon{margin: 32px auto 12px;}
.tags-title h1{color: #2E2E30;font-size: 20px;text-align: center; font-weight: normal;}
.tags-list .item{padding: 0 20px; height: 36px; line-height: 36px; border: 1px solid #B3B3B3; border-radius: 4px; margin-right: 12px; margin-bottom: 12px; font-size: 14px; color: #B3B3B3;}
.tags-list .item.on{border-color: #F85659; background: #F85659; color: #fff;}
.tags-list .btn_add{width:116px; height: 36px; border-radius: 4px; background: #EDEDED; font-size: 14px; color: #666666; display: flex; justify-content: center; align-items: center; margin-right: 12px;}
.tags-list .btn_add .icon{margin-right: 4px;}
.tags-list-content{width: 100%; display: flex; justify-content: flex-start; flex-wrap: wrap; border-bottom: 1px solid #EDEDED; padding-bottom: 28px;}
.tags-btns{padding:20px 20px 20px; display: flex; justify-content: flex-end;  align-items: center;}
.tags-btns .btn{width:88px; height: 36px; margin-left: 12px; background: #F5F5F5; border-radius: 4px; display: flex; justify-content: center; align-items: center; font-size: 14px; color: #B3B3B3;} 
.tags-btns.on .btn{color: #fff; background:#2E2E30 ;}
.tags-btns .btn .icon{margin-right: 4px;}


</style>